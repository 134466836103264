.sfmoma {
    padding: 3em var(--left-right-padding);
}

.sfmoma h3, .sfmoma h1 {
    font-family: 'Livvic Bold';
}

.coverbackground {
    background-color: #292929;
}

.coverbackground img, .sfmoma img {
    width: 100%;
}

.intro h3 {
    font-family: 'Livvic Bold';
}

.intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 60px;
    margin-top: 50px;
}

.introLeft {
    width: 40%;
    display: flex;
    flex-direction: column;
    row-gap: 9px;
}

.leftChunk {
    display: flex;
    flex-direction: column;
}

.leftChunk p {
    margin: 0px;
}

.leftChunk h3 {
    margin-bottom: 0px;
}

.introRight {
    width: 50%;
    display:flex;
    flex-direction: column;
}

.paddingTop {
    padding-top: 3em;
}

.orange {
    color: #DD9F45;
}

.challengebackground {
    background-color: #010101;
}

.challenge {
    color: white;
    padding: 1em var(--left-right-padding);
}

.posterdesign {
    display: grid;
    grid-template-columns: 0.49fr 0.51fr;
    column-gap: 2em;
}

.mockups {
    display: grid;
    grid-template-columns: 0.52fr 0.48fr;
    column-gap: 2em;
}

@media screen and (max-width: 700px) {
    .intro {
        flex-direction: column;
    }

    .introLeft, .introRight {
        width: 100%;
        max-width: 100%;
    }

    .posterdesign, .mockups {
        grid-template-columns: 1fr;
        row-gap: 2em;
    }
}