.frontline {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 100px;
    gap: 100px;
    margin-top: 75px;
    
}

.frontline h1 {
    font-family: 'Livvic Bold';
}

.relativeWrapper {
    position: relative;
    
}

.relativeWrapper h1 {
    font-size: 60px;
    margin: 0px;
    line-height: 70px;
}

.bigFont {
    font-family: 'Livvic';
    font-size: 39px;
    margin: 0px;
}

.right {
    padding-right: 35px;
}

.right p {
    font-size: 25px;
    margin-bottom: 40px;
}

.right ul {
    padding-left: 18px;
    font-size: 17px;
    color: #797979;
}

.bold {
    font-family: 'Livvic Bold'
}

.orangeBold {
    font-family: 'Livvic Bold Italic';
    color: #F5481A;
}

.underLined {
    height: 14px;
    position: absolute;
    top: 183px;
}

.stickers {
    text-align: left;
    padding: 0 var(--left-right-padding);
    padding-top: 100px;
}

.surprise {
    font-family: 'Livvic Bold';
    margin: 0;
    font-size: 50px;
}

.stickerInfo {
    font-family: 'Livvic';
    margin: 0;
    font-size: 23px;
}

.introTextBreak {
    display: none;
}

.moveableStickers {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 var(--left-right-padding) 0 var(--left-right-padding);
    column-gap: 2em;
    padding-top: 2em;
}

.star {
    width: 50px;
    padding-left: 10px;
}

.profile {
    max-width: 100%;
}

.right {
    position: relative;
    z-index: -2;
}

.orange {
    animation: sizeDramatic 2s infinite linear;
    position: absolute; 
    top: 0;
    right: 0;
    margin-right: 75%;
    margin-top: 60%;
}

.orangeMobile {
    display: none;
}

.projects {
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding: 100px var(--left-right-padding);
}



.helloSticky, .descriptionSticky {
    font-family: 'Livvic Bold';
    color: white;
    background-color: #F5481A;
    font-size: 20px;
}

.helloSticky {
    position: absolute;
    left: 245px;
    top: -10px;
    padding: 7px 20px;
    border-radius: 25px;
    rotate: 9.62deg;
}

.flowerSticky {
    position: absolute;
    height: 37px;
    left: 335px;
    top: 80px;
}

.starSubsection {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.starSticky {
    height: 58px;
}

.descriptionSticky {
    position: absolute;
    width: 135px;
    text-align: center;
    top: -50px;
    right: -175px;
    padding: 15px 20px;
    border-radius: 40px;
}

.arrow {
    position: absolute;
    width: 20px;
    top: 75px;
    left: -175px;
}

.translation {
    position: absolute;
    top: 85px;
    left: -185px;
    font-family: 'Livvic Bold';
}

.playperson {
    display: none;
}

@media screen and (max-width: 1235px) {
    .orange {
        margin-right: 71%;
    }
}

@media screen and (max-width: 1190px) {
    .surprise {
        font-size: 20px;
    }
    
    .stickerInfo {
        font-size: 20px;
    }
    
    .stickersPick {
        font-size: 50px;
    }
}  

@media screen and (max-width: 1015px) {


    .relativeWrapper {
        position: relative;
    }

    .playperson {
        display: block;
        max-width: 200px;
    }

    .bigFont {
        height: 0px;
        width: 0px;
        overflow:hidden;
    }

    .relativeWrapper h1 {
        height: 0px;
        width: 0px;
        overflow:hidden;
    }

    .right ul {
        height: 0px;
        width: 0px;
        overflow:hidden;
    }
    .right p {
        font-size: 23px;
        margin-top: 90px;
        text-align: center;
    }

    .right {
        padding-right: 100px;
    }

    .underLined {
        height: 0px;
        width: 0px;
        overflow:hidden;
    }

    .helloSticky {
        font-size: 16px;
        left: 710px;
        top: 55px;
        padding: 5px 20px;
        border-radius: 25px;
        white-space: nowrap;
        position: absolute;
        
    }

    .starSticky {
         position: absolute;
         height: 50px;
         left: 120px;
         top: 55px;
    }

    .flowerSticky {
        position: absolute;
        height: 27px;
        left: 820px;
        top: 40px;
    }

    .frontline h1 {
        font-size: 70px;
    }

    .frontline {
        margin: 0;
    }
}

@media screen and (max-width: 890px) {
    .stickers {
        padding-top: 3em;
    }

    .helloSticky {
        font-size: 16px;
        left: 670px;
        top: 55px;
        padding: 5px 20px;
        border-radius: 25px;
        white-space: nowrap;
    }

    .starSticky {
         position: absolute;
         height: 50px;
         left: 120px;
         top: 55px
    }

    .flowerSticky {
        height: 27px;
        left: 770px;
        top: 40px;
    }
}

@media screen and (max-width: 780px) {
    .helloSticky {
        font-size: 16px;
        left: 500px;
        top: 90px;
        padding: 5px 20px;
        border-radius: 25px;
        white-space: nowrap;
    }

    .starSticky {
         position: absolute;
         height: 50px;
         left: 100px;
         top: 100px
    }

    .flowerSticky {
        height: 27px;
        left: 600px;
        top: 80px;
    }
}


@media screen and (max-width: 700px) {
    .moveableStickers {
        grid-template-columns: 1fr 1fr;
        column-gap: 0.5em;
    }

    .frontline h1 {
        font-size: 50px;
    }

    .helloSticky {
        font-size: 16px;
        left: 330px;
        top: 100px;
        padding: 5px 20px;
        border-radius: 25px;
        white-space: nowrap;
    }

    .starSticky {
         position: absolute;
         height: 50px;
         left: 100px;
         top: 100px
    }

    .flowerSticky {
        height: 27px;
        left: 430px;
        top: 80px;
    }

    .right p {
        font-size: 20px;
    }
}

@media screen and (max-width: 500px) {
    .stickers {
        padding-top: 0;
    }

    .right p {
        font-size: 18px;
        margin-top: 20px;
    }

    .right {
        margin-top: 0px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .frontline {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }

    .helloSticky {
        font-size: 16px;
        left: 50px;
        top: 230px;
        padding: 5px 20px;
        border-radius: 25px;
        white-space: nowrap;
    }

    .starSticky {
         position: absolute;
         height: 50px;
         left: -150px;
         top: 230px
    }

    .flowerSticky {
        height: 27px;
        left: 130px;
        top: 210px;
    }

    
}

