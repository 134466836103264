.item {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 45%;
}

.line {
    margin: 0px;
    color: #AFB6BB;
}

.item a {
    color: black;
    text-decoration: none;
}

.item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.keywords {
    color: #797979;
    margin: 0;
}

.name {
    font-family: 'Livvic Bold';
    font-size: 50px;
    margin: 0;
    transition: 0.5s;
    color: #585858;
}

.name:hover {
    transition: 0.5s;
    color: black;
}

.subtitle {
    font-size: 28px;
    margin: 0;
    color: #828282;
    font-family: 'Karla Medium'
}

.desc {
    font-family: 'Karla Regular';
    color: #828282;
    font-size: 19px;
    margin: 0 0 0 0;
}

.image {
    position: relative;
    top: 0;
    transition: top 0.5s;
    border-radius: 30px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.image:hover {
    top: -0.5em;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

@media screen and (max-width: 1000px) {
    .item {
        flex-direction: column;
        width: 80%;
    }
}