.about {
    padding: 5em var(--left-right-padding);
}

.content {
    display: flex;
    flex-direction: row;
    padding-bottom: 3em;
    gap: 5em;
}

.details {
    font-family: 'Karla Medium';
}

.left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.images {
    width: 420px;
}

.top {
    position: relative;
}

.pronouns {
    background-color: #F5481A;
    width: fit-content;
    height: fit-content;
    padding: 0.35em 1em 0.35em;
    color: white;
    border-radius: 1.5em;
    font-size: 15px;
    margin-top: 30px;
}

.talk {
    font-size: 25px;
}

.talk {
    margin: 0;
}

.italic {
    font-family: 'Livvic Medium Italic';
}

.bold {
    font-family: 'Livvic Bold';
}

.doodles {
    padding-top: 3em;
}

.name {
    font-family: 'Livvic Bold';
    font-size: 60px;
    margin: 0;
    position: relative;
}

.nameSec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sun {
    position: absolute;
    bottom: -35px;
    left: -35px;
    width: 100px;
}

.doodlesMobile {
    display: none;
}

.star {
    position: absolute;
    width: 50px;
    top: -25px;
    right: -20px;
    
}

.aboutme ul {
    margin-left: -20px;
}

.aboutme li {
    padding-bottom: 10px;
}

.aboutPerson {
    width: 390px;
}

.previouslySection h1 {
    font-family: 'Karla Bold';
    color: #5E6167;
    font-size: 36px;
}

.previouslySection h4 {
    font-family: 'Karla Bold';
    color: #3F3F3F;
    font-size: 22px;
    margin-bottom: 10px;
}

.previouslySection p {
    font-family: 'Karla Regular';
    font-size: 17px;
    color: #828282;
    margin-bottom: 20px;
}

.orange {
    color: #F5481A;
}

@media screen and (max-width: 1150px) {
    .name {
        font-size: 50px;
    }

    .name img {
        width: 40px;
    }

    .details, .talk, .pronouns {
        font-size: 20.5px;
    }
    
    .images {
        max-width: 350px;
    }

    .content {
        padding-bottom: 1em;
    }

    .sun {
        bottom: -30px;
        left: -30px;
        width: 90px;
    }

    .star {
        width: 40px;
    }
}

@media screen and (max-width: 1000px) {
    .name {
        font-size: 40px;
    }

    .name img {
        width: 35px;
        padding-left: 0.1em;
    }

    .details, .talk, .pronouns {
        font-size: 18px;
    }
    
    .images {
        max-width: 300px;
    }

    .content {
        gap: 3em;
    }

    .sun {
        bottom: -25px;
        left: -25px;
        width: 75px;
    }

    .star {
        
    }
}

@media screen and (max-width: 825px) {
    .details, .talk, .pronouns {
        font-size: 17px;
    }
}

@media screen and (max-width: 768px) {
    .details, .talk, .pronouns {
        font-size: 16px;
    }
    
    .images {
        max-width: 250px;
    }

    .content {
        gap: 2em;
    }

    .sun {
        width: 67px;
    }

    .star {
        width: 30px;
    }
}

@media screen and (max-width: 720px) {
    .content {
        flex-direction: column;
        gap: 3em;
        align-items: center;
    }

    .doodles {
        display: none;
    }

    .doodlesMobile {
        display: flex;
    }

    .left {
        text-align: center;
    }

    .about {
        padding-top: 1em;
    }

    .images {
        max-width: 300px;
    }

    .sun {
        bottom: -25px;
        left: -25px;
        width: 75px;
    }

    .star {
        width: 40px;
    }
}

@media screen and (max-width: 425px) {
    .images {
        max-width: 100%;
    }

    .sun {
        width: 25%;
    }

    .star {
        width: 12%;
        padding-top: 10%;
        padding-right: 10%;
    }
}


@media screen and (max-width: 320px) {
    .sun {
        bottom: -20px;
        left: -20px;
    }
}