.coverbackground {
    background: #E7F0EC;
}

.imageDown {
    margin-bottom: -4px;
}

.coverbackground img {
    width: 100%;
}


.justworks img {
    width: 100%;
}

.bold {
    font-family: 'Karla Bold';
}

.justworks h1 {
    font-family: 'Karla Regular';
    font-size: 30px;
    font-weight: normal;
}

.justworks p {
    font-family: 'Karla Regular';
    color: #797979;
}

.justworks li {
    font-family: 'Karla Regular';
    color: #797979;
}

.spacing {
    margin: 50px 0px;
}

.halfSpacing {
    margin-top: 50px;
}

.justworks h3 {
    font-family: 'Karla Regular';
    font-size: 22px;
    font-weight: normal;
}

.justworks h4 {
    font-family: 'Karla Regular';
    font-size: 20px;
    font-weight: normal;
    color: #797979;
}

.black {
    color: black;
}

.intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 60px;
    margin-top: 50px;
    gap: 90px;
}

.intro h1 {
    font-family: 'Karla Regular';
    font-size: 30px;
    margin-top: 12px;
    font-weight: normal;
}

.intro h3 {
    font-family: 'Karla Regular';
    font-size: 16px;
}

.intro p {
    font-size: 16px;
}

.introLeft {
    display: flex;
    flex-direction: column;
}

.introRight {
    display:flex;
    flex-direction: column;
}

.infoCont {
    display: flex;
    flex-direction: row;
    gap: 55px;
}

.info h3 {
    margin-bottom: 0px;
    color: #797979;
}

.info p {
    margin-top: 7px;
    color: #797979;
}

.skip {
    background-color: #E7F0EC;
    color: #325B5B;
    font-family: 'Karla Bold Italic';
    font-size: 23px;
    border: none;
    border-radius: 3px;
    padding: 8px 22px;
    cursor: pointer;
}

.skipCont {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    margin-top: 70px;
}

.pt1 {
    margin-bottom: 75px;

}

.termContainer {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.termination {
    display: flex;
    flex-direction: row;
    gap: 150px;
}

.termination img {
    width: 60%;
}

.termCont {
    position: relative;
}

.subtitles {
    position: absolute;
    left: 280px;
    top: 200px;
}

.explore {
    display: flex;
    flex-direction: row;
    gap: 100px;
}

.exploreLeft {
    width: 48%;
}
.exploreRight {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.exploreContainer {
    margin-bottom: 70px;
}


.line {
    margin-top: 90px;
    margin-bottom: 60px;
}

.patternContainer {
    display: flex;
    flex-direction: row;
    gap: 150px;
    margin-bottom: 70px;
}

.pattern {
    display: flex;
    flex-direction: column;
}

.greenBackground {
    display: flex;
    flex-direction: row;
    gap: 50px;
    padding: 20px 60px;
    background-color: #E7F0EC;
    margin-top: 20px;
}

.greenLeft {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.greenLeft h4 {
    font-family: 'Karla Bold';
    color: #325B5B;
    font-size: 22px;
}

.greenRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
}

.formLeft {
    width: 1000px;
}

.formLeft h4 {
    margin-bottom: 15px;
}

.formList {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.editPaymentsCont {
    display: flex;
    flex-direction: row;
}

.lightGreen {
    padding: 30px 60px;
}

.finalHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.finalHeading img {
    height: 3px;
    width: auto;
}

.finalHeading h4 {
    font-family: "Karla Bold";
    color: #325B5B;
    font-size: 17px;
    margin-bottom: 0px;
    margin-top: 2px;
}

.finalContent {
    display: flex;
    flex-direction: column;
}

.finalContent p {
    font-size: 14px;
    padding-left: 60px;
    padding-right: 90px;
}

.darkGreen {
    background-color: #325B5B;
    padding: 0.1px 5px;
    margin-bottom: 0px;
}

.darkGreen h4 {
    color: white;
    font-family: 'Karla Regular';
    font-size: 22px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.lightGreen {
    margin-top: 0px;
    background: #E7F0EC;
    display: flex;
    flex-direction: row;
    padding-top: 80px;
    padding-bottom: 80px;
}

.finalLeft {
    width: 89%;
}

.finalRight {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.inlineRight h4 {
    font-family: 'Karla Bold';
    color: #325B5B;
}

.inlineContainer {
    background-color: #F4F4F4;
    display: flex;
    flex-direction: row;
    padding: 50px 100px 90px;
    margin-top: 70px;
    gap: 70px;
}

.inlineRight {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inlineLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 860px;
}

@media screen and (max-width: 700px) {
    .intro {
        flex-direction: column;
    }

    .introLeft, .introRight {
        width: 100%;
        max-width: 100%;
    }

    .imageAndContent, .findingsWrapper {
        flex-direction: column;
    }

    .content, .image {
        width: 100%;
    }

    .prototypes {
        flex-direction: column;
        padding: 30px;
    }    

    .notes, .reports {
        grid-template-columns: 1fr 1fr;
    }

    .learned {
        grid-template-columns: 1fr;
    }
}