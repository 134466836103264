.coverbackground {
    background-color: #EEF3D8;
}

.coverbackground img {
    width: 100%;
}

.gearbox {
    padding: 3em var(--left-right-padding);
}

.gearbox img {
    width: 100%;
}

.gearbox h1 {
    font-family: 'Karla Regular';
    font-size: 27px;
    font-weight: normal;
}

.gearbox h4 {
    font-family: 'Karla Regular';
    font-weight: normal;

}

.bold {
    font-family: 'Karla Bold';
}

.gearbox p {
    font-family: 'Karla Regular';
    font-size: 17px;
}

.intro h3 {
    font-family: 'Livvic Bold';
    font-size: 18px;
}

.intro p {
    font-size: 18px;
}

.intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 90px;
    margin-top: 50px;
}

.introLeft {
    width: 40%;
    display: flex;
    flex-direction: column;
    row-gap: 9px;
}

.leftChunk {
    display: flex;
    flex-direction: column;
}

.leftChunk p {
    margin: 0px;
}

.leftChunk h3 {
    margin-bottom: 0px;
}

.introRight {
    width: 50%;
    display:flex;
    flex-direction: column;
}
.gray {
    color: #797979;
    font-family: 'Karla Medium';
}

.green {
    color: #889735;
}

.skip {
    background-color: #EEF3D8;
    color: #889735;
    font-family: 'Karla Bold Italic';
    font-size: 19px;
    border: none;
    border-radius: 3px;
    padding: 8px 22px;
    cursor: pointer;
}

.skipCont {
    display: flex;
    justify-content: center;
    margin-bottom: 110px;
}

.whatIsCont h2 {
    font-size: 23px;
    font-family: 'Karla Bold Italic'
}

.gridWrapper {
    display: grid;
    grid-template-columns: 1.4fr 0.6fr;
    gap: 100px;
    background-color: #EEF3D8;
    padding: 50px 90px;
}

.gridWrapper img {
    height: 100%;
}

.whatIsCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hypoContainer {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
}

.hypoContainer img {
    width: 59%;
}

.hypothesis h4 {
    color: #797979;
    margin-bottom: 12px;
}

.hypothesis p {
    font-size: 17px;
    margin-top: 12px;
    margin-bottom: 120px;
}

.hypothesis a {
    text-decoration: none;
    font-family: 'Karla Medium';
    color: #659B61; 
}

.shortLine {
    width: 97%;
    margin-left: 0;
    margin-top: 0px;
    margin-bottom: 0px;
}

.tag {
    display: flex;
    flex-direction: row;
}

.leftSide {
    background-color: #E4EEB0;
    width: 50px;
}

.rightSide {
    background-color: #EEF3D8;
    padding: 45px 30px 1px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 110px;
    height: 180px;
}

.rightSideContent {
    font-family: 'Livvic Medium';
    width: 74%;
    font-size: 21px;
}

.rightSide img {
    margin-top: 10px;
    height: 50%;
    width: auto;
}

.bulletPoint {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
}

.bulletPoint h3 {
    color: #D8E592;
    background-color: #8AB262;
    display: block;
    padding: 7px 14px;
    border-radius: 25px;
    font-family: 'Karla Bold';
    font-size: 25px;
}

.semiPoint h4 {
    font-family: 'Karla Medium';
    color: #8AB262;
    font-size: 25px;
    margin-top: 23px;
    margin-bottom: 0px;
}

.semiPoint p {
    color: #797979;
}

.hmw {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px 140px;
}

.boldGreen {
    color: #889735;
    font-family: 'Karla Bold';
    font-size: 35px;

}

.surveys {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: 100%;
    gap: 150px;
    justify-content: space-between;
}

.surveys img {
    max-width: 40%;
    object-fit: cover;
}

.responses {
    padding-left: 25px;
    margin-bottom: 75px;
}

.responses li {
    font-family: 'Karla Regular';
}

.pieCharts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 50px;
}

.pieCharts img {
    max-width: 200px;
}

.groupTags {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px 70px;
    margin-top: 40px;
    margin-bottom: 100px;
}

.leftGreen {
    background-color: #8AB262;
    width: 50px;
}

.rightGray {
    background-color: #F4F4F4;
    padding: 10px 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.rightGray h4 {
    margin-bottom: 0px;
    color: #8AB262;
    font-size: 20px;
}

.rightGray p {
    color: #797979;
    font-size: 15px;
}

.withIcon img {
    max-width: 40px;
}

.withIcon h4 {
    margin-bottom: 0px;
    margin-top: 20px;
    font-size: 20px;
}

.withIcon p {
    margin-top: 15px;
    font-size: 17px;
}

.hmw1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px 90px;
    margin-bottom: 80px;
}

.grayQuote {
    padding-top: 50px;
}

.listingPoints {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.listingPoints p {
    margin: 0;
}

.synthesis {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.greenText {
    font-size: 20px;
    font-family: "Karla Bold";
    color: #8AB262;
    margin-bottom: 10px;
}

.containText {
    width: 400px;
    color: #797979;
}

.shorterLine {
    color: #889735;
}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal p {
    font-size: 15px;
}

.researchCont {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 30px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.research1 {
    width: 32%;
}

.research1 p {
    font-size: 15px;
    color: #797979;
    margin-bottom: 30px;
}

.research2 p {
    font-size: 15px;
    color: #797979;
    margin-bottom: 30px;
}

.research2 {
    width: 64%;
}

.research3 p {
    font-size: 15px;
    color: #797979;
    margin-bottom: 30px;
}

.research3 {
    width: 74%;
}

.insights {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
}

.insight {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.persona {
    border-radius: 25px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin-bottom: 30px;
}

.mobileFeatures {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
}

.mobileFeatures img {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 25px 25px 0 0;
}

.mobileFeature {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

.bottomList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.finalCont {
    margin-bottom: 60px;
}

.darkGreen {
    background-color: #659B61;
    padding: 0.1px 5px;
    margin-bottom: 0px;
}

.darkGreen h2 {
    color: white;
    font-family: 'Karla Regular';
    font-size: 22px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: normal;
}

.left h3 {
    margin-top: 0px;
    font-family: 'Karla Bold';
    padding-left: 60px;
    margin-bottom: 10px;
}

.halfGreen {
    margin-top: 0px;
    background: linear-gradient(to right, #EEF3D8 50%, #F4F4F4 50%);
    display: flex;
    flex-direction: row;
    gap: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.middle img {
    border-radius: 23px;
    width: 80%;
}

.left {
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.middle {
    width: 30%;
    padding-right: 0px;
    display: flex;
    justify-content: flex-end;
}

.right {
    width: 30%;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
}

.right h3 {
    font-family: 'Karla Regular';
    font-size: 13px;
    color: #797979;
    font-weight: normal;
}

.right p {
    padding-left: 44px;
    font-size: 15px;
    color: #797979;
}

.finalDesign {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin-bottom: 50px;
}

.finalHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.finalHeading img {
    height: 5px;
    width: auto;
}

.finalText p {
    margin-top: 0px;
}

@media screen and (max-width: 700px) {
    .intro {
        flex-direction: column;
    }

    .introLeft, .introRight {
        width: 100%;
        max-width: 100%;
    }

    .gridWrapper {
        gap: 0;
    }

    .gridWrapper, .hmw {
        grid-template-columns: 1fr;
    }

    .leftSide {
        width: auto;
        height: 50px;
        border-radius: 25px 25px 0 0;
    }

    .tag {
        flex-direction: column;
    }

    .rightSide {
        border-radius: 0 0 25px 25px;
        width: auto;
    }

    .pieCharts {
        flex-direction: column;
    }

    .pieCharts img {
        align-self: center;
    }

    .groupTags, .synthesis {
        grid-template-columns: 1fr;
    }

    .insights, .mobileFeatures {
        grid-template-columns: 1fr 1fr;
    }
}