.upper {
    background-color: #F7F5F5;
}

.upperPage{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 60px;
    padding: 0 var(--left-right-padding);
    padding-bottom: 85px;
    padding-top: 34px;

}

.underLined {
    height: 16px;
    position: absolute;
    top: 225px;
    right: 35px;
}

.upperLeft {
    position: relative;
}

.flowerPerson {
    height: 370px;
    margin-top: 50px;
}

.play {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 67px;
    font-family: 'Livvic Bold';
    line-height: 80px;
}

.subtitle {
    font-size: 17.5px;
    color: #5F5F5F;
    font-family: 'Karla Regular'
}

.playpeople {
    width: 100%;
}

.playprojects {
    display: flex;
    flex-wrap: wrap;
    gap: 90px;
    padding-top: 100px;
    
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 50px;
    }

    .subtitle {
        font-size: 18px;
    }
}

@media screen and (max-width: 425px) {
    .title {
        font-size: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}