.sticker {
    max-width: 100%;
    cursor: grab;
}

.sticker:hover {
    animation: shake 0.8s;
}

@keyframes shake{
  0%{
    transform: translateX(0)
  }
  25%{
    transform: translateX(10px);
  }
    
  50%{
    transform: translateX(-10px);
  }
  100%{
    transform: translateX(0px);
  }
} 