.coverbackground {
    background: #EDF3FF;
}

.imageDown {
    margin-bottom: -4px;
}

.coverbackground img {
    width: 100%;
}

.justworks {
    padding: 3em var(--left-right-padding);
}

.justworks img {
    width: 100%;
}

.bold {
    font-family: 'Livvic Bold';
}

.justworks h3 {
    font-family: 'Karla Regular';
    font-size: 25px;
    font-weight: normal;
}

.justworks p {
    font-family: 'Karla Regular'
}

.intro h3, .justworks h1 {
    font-family: 'Livvic Bold';
    font-size: 17px;
}

.spacing {
    margin: 50px 0px;
}

.halfSpacing {
    margin-top: 50px;
}

.expenses {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    margin-top: 50px;
}

.expenseContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.expenseContainer img {
    max-width: 300px;
}

.feedbackDrawing p {
    font-family: 'Karla Regular';
    font-size: 27px;
}

.feedbackDrawing h3 {
    font-family: 'Livvic';
    font-size: 14px;
    color: #797979;
}

.feedbackDrawing {
    background-color: #EFF3FB;
    margin-top: 65px;
    padding: 40px 80px;
}


.intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 60px;
    margin-top: 50px;
}

.introLeft {
    width: 40%;
    display: flex;
    flex-direction: column;
    row-gap: 9px;
}

.leftChunk {
    display: flex;
    flex-direction: column;
}

.leftChunk p {
    margin: 0px;
}

.leftChunk h3 {
    margin-bottom: 0px;
}

.introRight {
    width: 50%;
    display:flex;
    flex-direction: column;
}

.skip {
    background-color: #EDF3FF;
    color: #1E98FE;
    font-family: 'Karla Bold Italic';
    font-size: 19px;
    border: none;
    border-radius: 3px;
    padding: 8px 22px;
    cursor: pointer;
}

.skipCont {
    display: flex;
    justify-content: center;
    margin-bottom: 120px;
}

.gray {
    color: #7C7C7C;
    font-family: 'Livvic';
}

.lightGray {
    color: #797979;
}

.line {
    margin-top: 77px;
    margin-bottom: 50px;
}

.imageAndContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 50px;
    gap: 70px;
}

.question {
    background-color: #EFF3FB;
    margin-top: 50px;
    padding: 35px 100px;
}

.blueHighlight {
    background-color: #A8DCF4;
}

.content {
    width: 50%;
}

.smallComp {
    display: flex;
    flex-direction: row;
    gap: 100px;
}

.comp ul {
    list-style-type: none;
    font-family: 'Karla Regular';
    color: #797979;
    line-height: 30px;
}

.comp p {
    font-family: 'Karla Regular';
    color: #797979;
}

.userTest {
    max-width: 250px; /* Adjust the max-width as needed */
    width: 100%; /* Ensures the image takes the full width available up to max-width */
    height: auto; /* Ensures the aspect ratio is maintained */
    object-fit: contain;
}

.prototypesSec {
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.image {
    width: 45%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.bold {
    font-family: 'Livvic Bold';
}

.infoSection {
    display: flex;
    flex-direction: row;
    gap: 100px;
    justify-content: space-around;
}

.textPic {
    max-width: 270px;
    height: auto; /* Ensures the aspect ratio is maintained */
    object-fit: contain;
}


.prototypes {
    display: flex;
    flex-direction: row;
    background-color: #D0E7FD;
    padding: 50px;
    border-radius: 25px;
    gap: 20px;
}

.stickyNote {
    background-color: #D0E7FD;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    padding: 15px;
    width: 100px;
    height: 100px;
}

.stickyNote p {
    margin: 0;
    font-size: xx-small;
}

.notes {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.findings ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-size: x-large;
}

.findings li::before {
    content: '⭐';
    padding-right: 5px;
}

.findings li {
    margin-left: -40px;
    padding-bottom: 20px;
}

.findingsWrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 100px;
    row-gap: 40px;
    
}

.findingsWrapper img {
    max-width: 420px;
}

.withBlueLine {
    border-left: solid 2px #75BFFE;
    padding-left: 25px;
}

.withBlueLine p {
    font-family: 'Karla Regular'
}

.reports {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
}

.reports p {
    font-size: small;
}

.states {
    text-align: center;
}

.states img {
    max-width: 500px;
}

.blueBackground {
    background-color: #D0E7FD;
    padding: 15px;
    border-radius: 25px;
    text-align: center;
}

.learned {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
}

@media screen and (max-width: 700px) {
    .intro {
        flex-direction: column;
    }

    .introLeft, .introRight {
        width: 100%;
        max-width: 100%;
    }

    .imageAndContent, .findingsWrapper {
        flex-direction: column;
    }

    .content, .image {
        width: 100%;
    }

    .prototypes {
        flex-direction: column;
        padding: 30px;
    }    

    .notes, .reports {
        grid-template-columns: 1fr 1fr;
    }

    .learned {
        grid-template-columns: 1fr;
    }
}