.backgroundColor {
    background-color: #E9E9E9;
    margin-top: 100px;
}

.contact {
    text-align: left;
    padding: 5em var(--left-right-padding);
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.form {
    display: flex;
    flex-direction: row;
    gap: 3em;
}

.leftForm input:focus, .rightForm textarea:focus {
    outline-color: #4286F5;
}

.leftForm input, .rightForm textarea, .message, .submitMobile {
    font-family: 'Livvic';
    font-size: 25px;
}

.leftForm {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 2em;
}

.form div input, .submitMobile {
    border: 2px solid black;
    padding: 0.5em 1em;
    border-radius: 1.5em;
}

.rightForm {
    display: flex;
    height: 245px;
    width: 60%;
}

.rightForm textarea {
    border: 2px solid black;
    padding: 1em 1em;
    border-radius: 1.5em;
    resize: none;
    flex: 1;
}

.title {
    font-size: 50px;
    font-family: 'Livvic Bold';
    margin: 0;
    padding-bottom: 0.125em;
}

.submit, .submitMobile {
    width: 200px;
    background-color: #F5481A;
    color: white;
    cursor: pointer;
}

.submitMobile {
    display: none;
}

.message {
    text-align: center;
    padding-top: 0.5em;
}

@media screen and (max-width: 1000px) {
    .form {
        display: flex;
        flex-direction: column;
        gap: 2em;
    }
    
    .leftForm {
        width: 100%;
    }

    .rightForm {
        width: 100%;
    }

    .submit {
        display: none;
    }

    .submitMobile {
        display: block;
        margin-top: 2em;
    }

    .message {
        text-align: left;
    }
}

@media screen and (max-width: 950px) {
    .leftForm input, .rightForm textarea, .message, .submitMobile {
        font-size: 20px;
    }

    .title {
        font-size: 50px;
    }

    .submitMobile {
        width: 150px;
    }
}

@media screen and (max-width: 375px) {
    .leftForm input, .rightForm textarea, .message, .submitMobile {
        font-size: 18px;
    }

    .title {
        font-size: 30px;
    }

    .title {
        padding-bottom: 0.5em;
    }

    .leftForm {
        gap: 1em;
    }

    .form {
        gap: 1em;
    }
}