.line {
    margin: 100px 0px;
}

.pinterest p {
    font-size:18px;
    font-family: 'Karla Regular'
}

.pinterest h3 {
    font-size: 27px;
    font-family: 'Karla Regular';
    font-weight: normal;
}

.pinterest h2 {
    font-size: 27px;
    font-family: 'Karla Regular';
    font-weight: normal;
}

.lightGray {
    color: #5F5F5F;
    font-family: 'Karla Regular'
}

.imageDown {
    margin-bottom: -4px;
}

.coverbackground {
    background-color: #F7F5F5;
}

.coverbackground img {
    width: 100%;
}

.pinterest {
    padding: 3em var(--left-right-padding);
}

.pinterest img {
    width: 100%;
}

.intro h3, .pinterest h1 {
    font-family: 'Livvic Bold';
}

.intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 60px;
    margin-top: 50px;
}

.introLeft {
    width: 40%;
    display: flex;
    flex-direction: column;
    row-gap: 9px;
}

.leftChunk {
    display: flex;
    flex-direction: column;
}

.leftChunk p {
    margin: 0px;
}

.leftChunk h3 {
    margin-bottom: 0px;
}

.introRight {
    width: 50%;
    display:flex;
    flex-direction: column;
}

.endResult {
    display: flex;
    flex-direction: column;
    gap: 70px;
}

.gray {
    color: #7C7C7C;
    font-family: 'Livvic';
}

.red {
    color: #BD081C;
}

.line {
    margin: 50px 0;
}

.imageAndContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 50px;
}

.content {
    width: 50%;
}

.image {
    width: 45%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.bold {
    font-family: 'Livvic Bold';
}

.boldRed {
    font-family: 'Livvic Bold';
    color:#BD081C;
}

.redSectionText p {
    font-family: 'Karla Medium'
}

.redSectionText h2 {
    font-family: 'Karla Medium Italic';
    margin-top: 0px;
    color:#BD081C;
}

.redSection img {
    width: 60%;
    height: auto;
    border-radius: 20px;
}

.redSection1 {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.redSection1 img {
    width: 66%;
    height: 55%;
    border-radius: 20px;
}

.grayBackground {
    background-color: #F7F5F5;
    padding: 27px 50px;
}

.whySMB {
    display: flex;
    flex-direction: column;
}

.whySMB h3 {
    margin-bottom: 0px;
}

.whySMB img {
    margin-top: 25px;
    margin-bottom: 25px;
}

.boldItalic {
    font-family: 'Livvic Bold Italic';
}

.darkgrayBG {
    background-color: #979797;
    padding: 1px;
    margin-top: 100px;
    margin-bottom: 30px;
}

.whiteText {
    color: white;
    text-align: center;
    margin: 10px;
}

.adPoints {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.adPoints h3 {
    margin-top: 0px;
    margin-bottom: 14px;
    font-size: 23px;
}

.adPoints ul {
    padding-left: 20px;
    font-size: 15px;
    margin-top: 0px;
}

.adPoints img {
    width: 30%; /* Ensures the image takes the full width available up to max-width */
    height: auto; /* Ensures the aspect ratio is maintained */
    object-fit: contain;
}

.inquiries {
    display: flex;
    flex-direction: row;
    gap: 100px;
    margin-bottom: 80px;
}

.inquireChunk p {
    font-size: 17px;
    margin-top: 5px
}

.inquireChunk h3 {
    margin-bottom: 0px;
    font-family: 'Karla Regular';
    font-size: 20px;
    font-weight: normal;
}

.inquireChunk2 p {
    font-size: 17px;
    margin-top: 5px
}

.inquireChunk2 h3 {
    margin-bottom: 0px;
    font-family: 'Karla Regular';
    font-size: 20px;
    font-weight: normal;
}

.inquireChunk2 {
    width: 90%;
}

.inquiries1 {
    width: 80%;
}

.chunk {
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.insightBoxes {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.insightbox img{
    width: 10%;
     /* Ensures the image takes the full width available up to max-width */
    height: auto; /* Ensures the aspect ratio is maintained */
    object-fit: contain;
}

.insightbox p {
    font-size: 15px;
}

.insightbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 19px;
    border: 1px solid rgb(240, 238, 238);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.insightbox1 img{
    width: 10%;
     /* Ensures the image takes the full width available up to max-width */
    height: auto; /* Ensures the aspect ratio is maintained */
    object-fit: contain;
}

.insightbox1 p {
    font-size: 15px;
}

.insightbox1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border: 1px solid rgb(240, 238, 238);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding-left: 10px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 854px;
}

.insightbox2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 19px;
    border: 1px solid rgb(240, 238, 238);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding-left: 15px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.insightbox2 img{
    width: 8%;
     /* Ensures the image takes the full width available up to max-width */
    height: auto; /* Ensures the aspect ratio is maintained */
    object-fit: contain;
}

.insightbox2 p {
    font-size: 15px;
}

.analysis h2 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.analysis h3 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.analysis p {
    margin-top: 0px;
}

.findings1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.findingsWrapper {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.analysisSection {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
}

.analysis1 {
    width: 72%;
}


.box img {
    width: 60%;
}

.box p {
    font-size: 13px;
    padding-right: 50px;
}

.versus {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    margin-bottom: 60px;
}

.versusBox1 {
    padding-right: 50px;
    border-right: 3px dotted #BD081C;
}

.versusBox2 {
    padding-left: 50px;
}

.goalBox img {
    width: 60px;
}

.goalBox h3 {
    color: gray;
}

.goalBox {
    display: flex;
    flex-direction: row;
    gap: 11px;
    padding-right: 80px;
}

.goalContainer {
    width: 50%;
}

.goals {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-top: 20px;
}

.redSection2 h1 {
    color: white;
}

.redSection2 h2 {
    color: white;
}

.redSection2 h3 {
    color: white;
}

.redSection2 p {
    color: white;
}

.newContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.newBox {
    margin-bottom: 30px;
}


.newBox img {
    width: 90%;
}

.newBox p {
    font-size: 15px;
    font-family: 'Karla Regular'
}

.karlaItalic {
    font-family: 'Karla Bold Italic';
}

.wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.end1 {
    width: 120%;
    padding-right: 60px;
}


.end {
    padding-left: 100px;
}

.end img {
    border-radius: 20px;
}

.endDifferent img {
    border-radius: 20px;
}

.endDifferent {
    padding-left: 30px;
}

.endWrapper {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-top: 50px;
}

.mobileResponse {
    display: flex;
    flex-direction: row; 
    justify-content: center; 
    align-items: center;
    align-content: center;
}

.mobileBox1 {
    max-width: 60%;
    padding-left: 20px;
}

.mobileBox1 img {
    max-width: 80%;
}

.mobileBox2 {
    max-width: 60%;
}

.mobileBox2 img {
    max-width: 80%;
}

.statsImg {
    max-width: 90%;
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
}


.adPointsWrapper {
    display: flex;
    flex-direction: row;
    gap: 100px;
}

.adPoints {
    width: 50%
}

#box1 {
    width: 43%;
}

#box2 {
    width: 30%;
}

#box3 {
    width: 38%;
}

#box4 {
    width: 52%;
}

#box5 {
    width: 60%;
}

.medal {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.analysis2 {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.medal img {
    max-width: 120px;
}

.new {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.new p {
    margin-left: 50px;
}

.newBox2 p {
    margin-left: 50px;
}

.newBox2 {
    margin-bottom: 30px;
}


.newBox2 img {
    width: 90%;
}

.newBox2 p {
    font-size: 15px;
    font-family: 'Karla Regular'
}

#newDiff {
    margin-top: 45px;
}

.darkgray {
    background-color: #979797;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    
}

.darkgray h3 {
    color: white;
    font-size: 28px;
    letter-spacing: 1px;
    text-align: center;
}

.darkgray img {
    width: 83px;
    height: auto;
    object-fit: contain;
}

.grayBG {
    background-color: #F7F5F5;
}

.subContent {
    padding: 65px;
    display: flex;
    flex-direction: row;
    gap: 70px;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 30px;
}

.subRight {
    width: 45%;
}

.subLeft {
    width: 65%;
}

.subLeft p {
    font-family: 'Karla Medium Italic';
    font-size: 16px;
}

.subLeft img {
    border-radius: 25px;
}

.subRight p {
    font-family: 'Karla Medium';
    font-size: 16px;
}

.subRight img {
    width: 65px;
}

.halfredBG {
    display: flex;
    flex-direction: row;
    padding: 65px;
    gap: 40px;
    align-items: flex-end;
    background-image: url("../images/halfredBG.png");
    background-repeat: no-repeat;
    padding-bottom: 120px;
    background-size: cover;
    background-position: center;
    margin-top: 60px;
}

.redLeft {
    width: 50%;
    
}

.redLeft p {
    font-family: 'Karla Medium';
    font-size: 16px;
    margin-top: 7px;
}

.redLeft img {
    width: 55px;
}

.redRight img {
    border-radius: 25px;
}

.redRight {
    width: 80%;
}

.adContainer {
    margin-top: 85px;
}

.adText p {
    margin-top: 0px;
    margin-bottom: 40px;
    color: #797979;
}

.adText h3 {
    margin-bottom: 15px;
}

.redLeft h3 {
    color: #797979;
}

.redLeft h4 {
    color: #BD081C;
    font-family: 'Karla Italic';
    font-size: 21px;
    margin-bottom: 0px;
    margin-top: 15px;
}

.adContainerConnected {
    margin-top: 20px;
    margin-bottom: 80px;
}

.futureDiv {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.futureChild {
    width: 50%;
}

.metricsDiv {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-bottom: 70px;
}

.metricsChild {
    display: flex;
    flex-direction: column;
    
}

.metricsChild h3 {
    text-align: center;
    color:#BD081C;
    font-family: "Karla Italic";
    font-size: 25px;
    font-weight: normal;
}

.metricsChild p {
    text-align: center;
    font-size: 16px;
}

.metricsChild img {
    height: 100px;
    width: auto;
    object-fit: contain;
}

@media screen and (max-width: 700px) {

    #newDiff {
        margin-top: 10px;
    }

    .endDifferent {
        padding-left: 0px;
    }

    .end {
        padding-left: 0px;
    }

    .end img {
        width: 100%;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
    }

    .newBox img {
        width: 100%;
    }

    .new {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .new img {
        margin-left: 0px;
        width: 100%;
    }
    
    .new p {
        margin-left: 0px;
    }
    
    .newBox2 p {
        margin-left: 0px;
    }
    
    
    .newBox2 img {
        width: 100%;
    }
    
    .newBox2 p {
        font-size: 15px;
        font-family: 'Karla Regular'
    }

    .newContainer {
        display: flex;
        flex-direction: column;
    }

    .goalBox p {
        font-size: 17px;
    }

    .goalBox {
        display: flex;
        flex-direction: row;
        gap: 11px;
        width: 100%;
    }
    
    .goalContainer {
        width: 100%;
    }
    
    .goals {
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        margin-top: 20px;
    }

    .versusBox1 {
        padding-right: 0px;
        border-right: none;
        border-bottom: 3px dotted #BD081C;
        padding-bottom: 30px;
    }
    
    .versusBox2 {
        padding-left: 0px;
        padding-top: 30px;
    }

    .versus {
        display: flex;
        flex-direction: column;
    }

    .analysis2 {
        margin-top: 25px;
    }

    .mobileMedal {
        display: flex;
        flex-direction: row;
        width: 210%;
        gap: 50px;
    }

    .medal {
        display: flex;
        justify-content: flex-start;
        width: 50%;
    }

    .medal img {
        height: 121px;
    }

    .analysis2 img {
        width: 30%;
    }

    #box5{
        width: 37%;
    }

    #box4 {
        width: 30%;
    }

    #box3 {
        width: 22%;
    }

    #box2 {
        width: 19%;
    }

    #box1 {
        width: 28%;
    }

    .box img {
        width: 40%;
    }
    
    .box p {
        font-size: 16px;
        padding-right: 0px;
    }

    .findingsWrapper {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        width: 100%;
    }

    .analysis1 {
        width: 100%;
    }

    .analysisSection {
        display: flex;
        flex-direction: column;
    }

    .adWrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .adWrapper img {
        width: 100%;
        margin-top: 10px;
    }
    
    

    .adPoints {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }


    .inquiries {
        display: flex;
        flex-direction: column;
        gap: 0px;
    }

    .inquiries1 {
        display: flex;
        flex-direction: column;
    }

    .inquireChunk img {
        margin-top: 20px;
    }

    .insightBoxes {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .chunk {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .insightbox {
        width: 100%;
    }

    .insightbox1 {
        width: 100%;
    }

    .intro {
        flex-direction: column;
    }

    .introLeft, .introRight {
        width: 100%;
        max-width: 100%;
    }

    .mobileResponse {
        display: flex;
        flex-direction: column;
    }

    .mobileBox1 {
        max-width: 100%;
        padding-left: 0px;
    }

    .mobileBox1 img {
        max-width: 100%;
    }

    .mobileBox2 {
        max-width: 100%;
    }

    .mobileBox2 img {
        max-width: 100%;
    }

    .statsImg {
        max-width: 100%;
        margin-left: 0px;
    }

    .redSection1 {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .redSection1 img {
        width: 100%;
    }

    .content, .image {
        width: 100%;
    }

    .prototypes {
        flex-direction: column;
        padding: 30px;
    }    

    .notes, .reports {
        grid-template-columns: 1fr 1fr;
    }

    .learned {
        grid-template-columns: 1fr;
    }
}