.coverbackground {
    background: linear-gradient(rgba(246,34,234,0.33), rgba(123,160,255,0.4));
}

.coverbackground img {
    width: 100%;
}

.innod {
    padding: 3em var(--left-right-padding);
}

.innod img {
    width: 100%;
}

.intro h3 {
    font-family: 'Livvic Bold';
}

.intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 150px;
    margin-top: 50px;
}

.introLeft {
    width: 40%;
    display: flex;
    flex-direction: column;
    row-gap: 9px;
}

.leftChunk {
    display: flex;
    flex-direction: column;
}

.leftChunk p {
    margin: 0px;
}

.leftChunk h3 {
    margin-bottom: 0px;
}

.introRight {
    width: 50%;
    display:flex;
    flex-direction: column;
}

.line {
    margin: 50px 0;
}

.gray {
    color: #7C7C7C;
    font-family: 'Livvic';
}

.instagramPosts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.instagramPosts img {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.hex {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}

.workshop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.rgb {
    display: grid;
    grid-template-columns: 0.88fr 1.06fr 1.06fr;
    gap: 20px;
}

.tabContainer {
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    border-radius: 30px;
    height: 40px;
}

.activeTab1 {
    width: 50%;
    border-right: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    background-color: #659B61;
}

.activeTab2 {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #659B61;
}

.inactiveTab1 {
    width: 50%;
    border-right: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.inactiveTab2 {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.inactiveTab1 p {
    font-family: 'Livvic Bold';
}

.inactiveTab2 p {
    font-family: 'Livvic Bold';
}

.activeTab1 p {
    font-family: 'Livvic Bold';
    color: white;
}

.activeTab2 p {
    font-family: 'Livvic Bold';
    color: white;
}

.flyers {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
}

.flyers img {
    width: 49%;
}

.packaging {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 30px;
}

.packaging img {
    width: 49%;
}


.cymk {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 60px;
}

.cymk img {
    width: 49%;
}

.cymkChild {
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.cymkChild img {
    width: 100%;
}

@media screen and (max-width: 700px) {

    .cymk {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-top: 60px;
    }
    
    .cymk img {
        width: 100%;
    }
    
    .cymkChild {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
    
    .cymkChild img {
        width: 100%;
    }

    .packaging {
        display: flex;
        flex-direction: column;
    }

    .packaging img {
        width: 100%;
    }

    .flyers {
        display: flex;
        flex-direction: column;
    }

    .flyers img {
        width: 100%;
    }
    
    .intro {
        flex-direction: column;
    }

    .introLeft, .introRight {
        width: 100%;
        max-width: 100%;
    }

    .instagramPosts {
        grid-template-columns: 1fr 1fr;
    }

    .hex, .rgb {
        grid-template-columns: 1fr;
    }
}