.item {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.item img {
    width: 100%;
}

.keywords {
    color: #797979;
    margin: 0;
}

.name {
    font-family: 'Livvic Bold';
    font-size: 50px;
    margin: 0;
    transition: 0.5s;
    color: #585858;
    padding-right: 20px;
}

.name:hover {
    transition: 0.5s;
    color: black;
}

.subtitle {
    font-size: 35px;
    margin: 0;
    color: #828282;
}

.desc {
    font-family: 'Livvic';
    color: #828282;
    font-size: 22px;
    margin: 20px 0 0 0;
}

.image {
    position: relative;
    top: 0;
    transition: top 0.5s;
    border-radius: 30px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.image:hover {
    top: -0.5em;
}

.content {
    width: 100%;
}

.content a {
    color: black;
    text-decoration: none;
}

@media screen and (max-width: 1000px) {
    .item {
        flex-direction: column;
    }
}