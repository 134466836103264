:root {
  --left-right-padding: 7em;
  --max-width: 1200px;
}

html, body {
  position: relative;
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FBFBFB;
  font-family: 'Livvic';
}

.limitWidth {
  max-width: var(--max-width);
  margin: 0 auto;
}

@font-face {
  font-family: 'Karla Bold';
  src: url('./fonts/Karla-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Karla Italic';
  src: url('./fonts/Karla-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Karla Medium';
  src: url('./fonts/Karla-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Karla Medium Italic';
  src: url('./fonts/Karla-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Karla Regular';
  src: url('./fonts/Karla-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Karla Bold Italic';
  src: url('./fonts/Karla-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Livvic Bold Italic';
  src: url('./fonts/Livvic-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Pro Bold';
  src: url('./fonts/SF-Pro-Display-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Livvic';
  src: url('./fonts/Livvic-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Livvic Medium';
  src: url('./fonts/Livvic-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Livvic Medium Italic';
  src: url('./fonts/Livvic-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Livvic SemiBold';
  src: url('./fonts/Livvic-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Livvic Bold';
  src: url('./fonts/Livvic-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Margin';
  src: url('./fonts/Margin\ DEMO.otf') format('opentype');
}

.italic {
  font-family: 'Livvic Medium Italic';
}

.bold {
  font-family: 'Livvic Bold';
}

h1 {
  font-size: 35px;
}

h2 {
  font-size: 30px;
}

p, a {
  font-size: 18px;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.spinning {
  animation: rotation 8s infinite linear;
}

iframe {
  width: 100%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinningReverse {
  animation: rotationReverse 5s infinite linear;
}

@keyframes rotationReverse {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.twinkle {
  animation: gone 2s infinite linear;
}

@keyframes gone {
  0%, 100% {
      opacity: 100%;
  }
  50% {
      opacity: 0%;
  }
}

.moveable-line, .moveable-control.moveable-origin {
  display: none;
}

.line {
    margin: 50px 0;
}

@media screen and (max-width: 950px) {
  :root {
    --left-right-padding: 5em;
  }
} 

@media screen and (max-width: 650px) {
  :root {
    --left-right-padding: 3em;
  }
}

@media screen and (max-width: 440px) {
  :root {
    --left-right-padding: 2em;
  }
}